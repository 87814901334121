import React, { Component } from 'react'
import { connect } from 'react-redux';
import NavigationItems from '../NavigationItems/NavigationItems'
import styles from './Toolbar.module.css'
import Grid from '@material-ui/core/Grid'
import SyncIcon from "@material-ui/icons/Sync";
import Button from "@material-ui/core/Button";

import { InventoryService } from "../../../services/InventoryService";
import firebase from "../../../firebase";
const db = firebase.firestore();
const inventoryService = new InventoryService();


class Toolbar extends Component {
    render() {
        // console.log("isAuthenticated", this.props.isAuthenticated)
        return (
            <header className = {styles.Toolbar}>
                <nav className = {styles.DesktopOnly}>
                    <NavigationItems isAuth = {this.props.isAuthenticated}/>
                </nav>
                {this.props.isAuthenticated ? (
                    <nav className={styles.sync}>
                      <Button
                      disabled
                        variant="contained"
                        color="default"
                        // className={classes.button}
                        startIcon={<SyncIcon />}
                        onClick={async () => {
                          try {
                            db.collection("syncproducts")
                              .doc("ecU4ZpHT7mgsaMv1lPLf")
                              .set({ loader: true, error: false, workdone: false })
                              .then(() => {
                                console.log("Document successfully written!");
                              });
        
                            const result = await inventoryService.syncProductsWithPos();
                            console.log("syncProductsWithPos : ", result);
        
                            if (result.success === false) {
                              db.collection("syncproducts")
                                .doc("ecU4ZpHT7mgsaMv1lPLf")
                                .set({ loader: false, error: true, workdone: false })
                                .then(() => {
                                  console.log("Document successfully written!");
                                });
                            } else if (result.success === true) {
                              db.collection("syncproducts")
                                .doc("ecU4ZpHT7mgsaMv1lPLf")
                                .set({ loader: false, error: false, workdone: true })
                                .then(() => {
                                  console.log("Document successfully written!");
                                });
                            }
                          } catch (err) {
                            db.collection("syncproducts")
                              .doc("ecU4ZpHT7mgsaMv1lPLf")
                              .set({ loader: false, error: err, workdone: false })
                              .then(() => {
                                console.log("Document successfully written!");
                              });
                          }
                        }}
                      >
                        Sync Products
                      </Button>
                    </nav>
                  ) : null}

            </header>
        )
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.userId
    }
}

export default connect(mapStateToProps)(Toolbar);