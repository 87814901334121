import { POSAxios, WordpressAxios } from "./axios";
import Axios from "./axios";
import axios from "axios";
var cancelTokenSource = axios.CancelToken.source();

const appendURL = process.env.NODE_ENV === "production" ? "/server" : "";

export class InventoryService {
  async GetProductDetails(productSKU) {
    const response = await WordpressAxios.get(`/products/`, {
      params: { sku: productSKU },
    });
    console.log("ressss : ", response)
    return response.data;
  }

  async UpdateProductDetails(productId, data) {
    const res = await WordpressAxios.put(`/products/${productId}`, data);
    return res.data;
  }
  async getAllProducts() {
    const res = await WordpressAxios.get("products");
    return res.data;
  }
  async createProduct(data) {
    const res = await WordpressAxios.post("products", data);
    return res.data;
  }

  async GetPOSProductDetails(upc, itemName = "") {
    const response = await Axios.get(appendURL + "/api/getPOSProduct", {
      params: { upc, itemName },
    });
    return response.data;
  }
  async UpdatePOSProducts(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/pos/Product/ManageItem",
      data
    );
    return res.data;
  }
  async SyncInventory() {
    const res = await Axios.get(appendURL + "/api/sync");
    console.log(res.data);
    return res.data;
  }
  async UpdateProductFields(data) {
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/product/update",
      data
    );
    return res.data;
  }

  async CreateNotFoundItems(data) {
    /**
     * data format
     * {Item:String,Description:String,Quantity:String,Price:String,sku:String,Barcode:String,PosSKU:String,InvoiceName:String}
     */
    const res = await Axios.post(appendURL + "/api/invoice/notfound", data);
    return res.data;
  }

  async CreateScanInvoiceData(data) {
    //console.log("IN INVENTORY SERVICE");
    //console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/scaninvoicedata",
      data
    );
    return res.data;
  }
  async UpdateInvoiceData(invoiceName, invoiceNo, date, itemNo) {
    console.log(invoiceName);
    console.log(invoiceNo);
    console.log(itemNo);
    console.log(date);
    const res = await Axios.post(appendURL + "/api/invoice/updateinvoicedata", {
      params: {
        invoiceName: invoiceName,
        invoiceNo: invoiceNo,
        date: date,
        itemNo: itemNo,
      },
    });
    return res.data;
  }

  async updateSaveInvoiceStatus(obj) {
    console.log('obj : ', obj);
    const res = await Axios.post(appendURL + "/api/invoice/updatesaveinvociestatus", {
      params: obj,
    });
    return res.data;
  }

  async UpdateDBafterPosUpdate(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/updatedbafterposupdate",
      data
    );
    console.log(res);
  }

  async UpdateDBAfterPosUpdateForLinking(data) {
    // const res = await Axios.post(
    //   appendURL + "/api/invoice/updatedbafterposupdateforlinking",
    //   data
    // );
    try {
      const res = await Axios.post(
        appendURL + "/api/invoice/updatedbafterposupdateforlinking",
        data
      );
      console.log("res-->", res);
      return res;
    } catch (error) {
      console.log("error-->", error);
      return error;
    }
  }

  async saveDetails(data) {
    console.log(data);
    const res = await Axios.post(appendURL + "/api/invoice/savedetails", data);
    return res.data;
  }

  async reversePOSUpdate(invoiceName, invoiceNo, date, itemNo) {
    console.log(invoiceName);
    console.log(invoiceNo);
    console.log(itemNo);
    console.log(date);
    const res = await Axios.post(appendURL + "/api/invoice/reverseposupdate", {
      params: {
        invoiceName: invoiceName,
        invoiceNo: invoiceNo,
        date: date,
        itemNo: itemNo,
      },
    });
    return res.data;
  }

  async gethandwrittenPosLogs(data) {
    console.log("data : ", data);

    const res = await Axios.get(
      appendURL + "/api/invoice/gethandwrittenposlogs",
      {
        params: {
          invoicename: data.invoicename,
          itemNo: data.itemNo,
          sku: data.sku,
          updatedate: data.updatedate,
        },
      }
    );
    return res.data;
  }

  async linkingCorrect(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/linkingcorrect",
      data
    );
    return res;
  }

  // added by Deepanshu
  async linkingCorrectInScaninvoice(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/linkingCorrectInScaninvoice",
      data
    );
    return res;
  }

  async saveDetailsInScaninvoice(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/saveDetailsInScaninvoice",
      data
    );
    return res.data;
  }

  async linkManually(data) {
    console.log(data);

    try {
      const res = await Axios.post(appendURL + "/api/invoice/linkmanually", data, {
        cancelToken: cancelTokenSource.token
      });
      console.log(">> res : ", res)

      return res;
    } catch (error) {
      console.log("error : ", error)
      return error;

    }

  }

  // async linkManually(data) {
  //   console.log(data);

  //   const res = await Axios.post(appendURL + "/api/invoice/linkmanually", data);
  //   return res;
  // }

  async getHicksvilleData(value) {
    console.log("in inventory for hicks data");
    const res = await Axios.get(appendURL + "/api/invoice/gethicksvilledata", {
      params: { input: value },
    });
    return res.data;
  }

  async getSavedInvoices(data) {
    console.log("in inventory for saved invoices");
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/getsavedinvoices", {
      params: data,
    });
    return res.data;
  }

  async getNewInvoiceStatus(data) {
    console.log("data : ", data);

    const res = await Axios.get(appendURL + "/api/invoice/getnewinvoicestatus");
    return res.data;
  }

  async UpdateDBProduct(data) {
    console.log(data);
    const res = await Axios.put(appendURL + "/api/invoice/pos/update", data);
    return res.data;
  }
  async CreateDBProduct(data) {
    const res = await Axios.post(appendURL + "/api/invoice/pos/create", data);
    return res.data;
  }

  async getInitialSyncedData(dateObj) {
    console.log("In inventory service");
    console.log(dateObj);
    const res = await Axios.get(appendURL + "/api/invoice/pos", dateObj);
    console.log(res);
    return res.data;
  }

  async generateLog(data) {
    console.log(data);
    const res = await Axios.post(appendURL + "/api/invoice/generatelog", data);
    return res;
  }

  async linkManuallyLog(data) {
    console.log(data);
    try {


      const res = await Axios.post(
        appendURL + "/api/invoice/linkmanuallylog",
        data
        , {
          cancelToken: cancelTokenSource.token
        });
      console.log(">> linkManuallyLog: ", res)
      return res;
    } catch (error) {
      console.log(">> linkManuallyLog: error ", error)
      return error;

    }
  }

  // async linkManuallyLog(data) {
  //   console.log(data);
  //   const res = await Axios.post(
  //     appendURL + "/api/invoice/linkmanuallylog",
  //     data
  //   );
  //   return res;
  // }

  // added by Deepanshu
  async getLinkingLogsXlsx(data) {
    console.log(data);
    const res = await Axios({
      url: appendURL + "/api/invoice/getlinkinglogsxlsx",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }

  // added by Deepanshu
  async getPosLogsXlsx(data) {
    console.log(data);
    const res = await Axios({
      url: appendURL + "/api/invoice/getposLogsxlsx",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }

  async reverseUpdate(data) {
    console.log(data);

    const res = await Axios.post(
      appendURL + "/api/invoice/reverseupdate",
      data
    );
    return res.data;
  }

  async UnidentifiedLog(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/unidentifiedlog",
      data
    );
    return res;
  }

  async handwrittenPosLogs(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/handwrittenposlogs",
      data
    );
    return res;
  }
  async GethandwrittenLogs(invName) {
    const data = {
      invoiceName: invName,
    };
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/gethandwrittenlogs", {
      params: data,
    });
    return res;
  }

  async fetchProductFromPosList(data) {
    console.log(data);
    const res = await Axios.get(
      appendURL + "/api/invoice/fetchproductfromposlist",
      data
    );
    return res.data;
  }

  async posLogs(data) {
    console.log(data);
    const res = await Axios.post(appendURL + "/api/invoice/poslogs", data);
    return res.data;
  }

  async getItemForHandwrittenInvoice(data) {
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/getitemhandwritten", {
      params: data,
    });
    return res.data;
  }
  async UpdateInvoiceDetails(data) {
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/updateinvoicedetails",
      data
    );
    return res.data;
  }
  async UpdateVerifyDetails(data) {

    const res = await Axios.put(
      appendURL + "/api/invoice/updateverifydetails",
      data
    );
    return res.data;
  }
  async linkingCorrectTrue(data) {
    console.log(data);
    const res = await Axios.post(appendURL + "/api/invoice/linkingcorrecttrue", data);
    return res;
  }

  async InsertAllProducts(data) {

    try {


      console.log("<><> InsertAllProducts: ", data);
      const res = await Axios.post(appendURL + "/api/allproducts", data, {
        cancelToken: cancelTokenSource.token
      });
      console.log(">> InsertAllProducts : ", res)
      return res.data;
    } catch (error) {
      console.log(">> InsertAllProducts : error ", error)
      return error;

    }
  }

  // async InsertAllProducts(data) {
  //   console.log(data);
  //   const res = await Axios.post(appendURL + "/api/allproducts", data);
  //   return res.data;
  // }
  async getAllProductsDataByUsername(data) {
    // console.log(barcode);
    const res = await Axios.get(appendURL + "/api/getallproductsdatabyusername", {
      params: data,
    });
    return res.data;
  }
  async getInvoicesPDF(data) {
    console.log(data);
    const res = await Axios({
      url: appendURL + "/api/generateInvoicesPDF",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }



  // ############## Handwritten Functions ############

  // Function for handwritten
  async getHandwrittenSavedInvoices(data) {
    console.log("in inventory for saved invoices");
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/gethandwrittensavedinvoices", {
      params: data,
    });
    return res.data;
  }

  async CreateSaveHandwrittenInvoiceData(data) {
    //console.log("IN INVENTORY SERVICE");
    //console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/savehandwritteninvoicedata",
      data
    );
    return res.data;
  }

  // added by Deepanshu
  async UpdateHandWrittenProductFields(data) {
    const res = await Axios.put(
      appendURL + "/api/handwritteninvoice/product/update",
      data
    );
    return res.data;
  }

  async UpdateSaveHandwrittenInvoiceData(data) {
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/updatesavehandwritteninvoicedata",
      data
    );
    return res.data;
  }

  async UpdateHandwrittenInvoiceDetails(data) {
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/updatehandwritteninvoicedetails",
      data
    );
    return res.data;
  }

  async saveDetailsInSaveHandwritten(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/saveDetailsInScaninvoice",
      data
    );
    return res.data;
  }

  async handwrittenLinkingLogs(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/handwrittenlinkinglogs",
      data
    );
    return res;
  }


  async linkManuallyHandwritten(data) {
    console.log(data);

    const res = await Axios.post(appendURL + "/api/invoice/linkmanuallyhandwritten", data);
    return res;
  }


  async getHandwrittenLinkingLogsXlsx(data) {
    console.log(data);
    const res = await Axios({
      url: appendURL + "/api/invoice/gethandwrittenlinkinglogs",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }

  async getHandwrittenPosLogsXlsx(data) {
    console.log(data);
    const res = await Axios({
      url: appendURL + "/api/invoice/gethandwrittenposLogsxlsx",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }

  async getHandwrittenNewInvoiceStatus(data) {
    console.log("data : ", data);

    const res = await Axios.get(appendURL + "/api/invoice/gethandwrittennewinvoicestatus");
    return res.data;
  }

  async updateHandwrittenSaveInvoiceStatus(obj) {
    console.log('obj : ', obj);
    const res = await Axios.post(appendURL + "/api/invoice/updatehandwrittensaveinvociestatus", {
      params: obj,
    });
    return res.data;
  }


  async removeProductFromHandwrittenInvoice(data) {
    console.log("removeProductFromHandwrittenInvoice : ", data)
    // console.log(data);
    let res = await Axios.put(appendURL + "/api/invoice/removeProductFromHandwrittenInvoice", data);
    return res.data;
  }

  async removeProductFromHandwrittenAllProduct(data) {
    console.log("removeProductFromHandwrittenAllProduct : ", data)
    // console.log(data);
    let res = await Axios.put(appendURL + "/api/invoice/removeProductFromHandwrittenAllProduct", data);
    return res.data;
  }

  // ############## Handwritten Functions ############


  async cancelRequest() {
    cancelTokenSource.cancel('Request canceled by user');
    // setTimeout(() => {
      cancelTokenSource = axios.CancelToken.source();
    // }, 3000);
    return "request canceled"
  }

  async UpdateSaveInvoiceData(data) {
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/updatesaveinvoicedata",
      data
    );
    return res.data;
  }
}
