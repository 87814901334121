const loadersDataReducer = (state = [], action) => {
    switch (action.type) {
        case "LOADERDATA":
            return { ...state, loaderData: action.payload }
        case "CANCEL_LOOP":
            return { ...state, cancel_loop: action.payload.cancel_loop }
        case "SKIP_ITERATION":
            return { ...state, skip_iteration: action.payload.skip_iteration }
        default:
            return state
    }

}


export default loadersDataReducer;